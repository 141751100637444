<template>
  <div class="rules-tooltip" :style="rulesStyle" v-if="isShow">
    <div class="rules-tooltip-header">
      <h4>Rules preview</h4>
      <div class="zq--box-msg--header-close" @click="hideRulesTooltip">
        <span>&times;</span>
      </div>
<!--      <i class="fa fa-window-close" @click="hideRulesTooltip"></i>-->
    </div>
    <RulesItem :rulesData="ruleSetsData.rules" />
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import RulesItem from '@/shared/components/RulesItem';

export default {
  components: {
    RulesItem
  },
  props: {
    ruleSetsData: Object,
    isShow: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      windowWidth: '',
    }
  },
  computed: {
    ...mapGetters('theme', ['theme']),
    rulesStyle() {
      const width = this.windowWidth > 1200 ? 650 : 220;
      return {
        top: `${this.ruleSetsData.cord.y}px`,
        right: this.theme === 'default' ? `100px` : '90px',
        width: `${width}px`
      }
    }
  },
  methods: {
    hideRulesTooltip() {
      this.$emit('hideRulesTooltip');
    },
    handleResize() {
      this.$emit('hideRulesTooltip')
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    this.windowWidth = window.innerWidth
    window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize);
  },
}
</script>

<style lang="scss">
.rules-tooltip {
  position: absolute;
  width: 600px;
  height: auto;
  min-height: 200px;
  z-index: 1110;
  background: #ffffff;
  border-radius: var(--zq--border-radius);
  border: 1px solid #ffffff;
  transition: 0.2s;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
  padding: 5px 10px;

  .zq--box-msg--header-close {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }

  .rules-tooltip-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }
}


</style>